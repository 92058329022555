<template>

    <div class="w-full h-full px-3 py-4">

        <All model="UserApp" :immediate="true" :query="query" :data.sync="datauser" v-slot="{data,loading}"></All>

        <div class="h-full w-full rounded-lg shadow_box flex flex-col p-2 overflow-hidden" :class="'bg-module-'+mode">
            <div class="h-full pb-2 overflow-hidden no-scrollbar w-full flex flex-col">
                <div class=" h-12 w-full flex flex-row justify-start items-center px-2">
                    <span class="text-xl font-semibold" :class="'text-dfont-'+mode">{{ $t('edituser') }}</span>
                </div>
                <div class="flex-1 flex flex-col overflow-hidden">
                    <div class="h-8 px-2 py-1">
                        <el-steps :active="active" finish-status="success">
                            <el-step></el-step>
                            <el-step></el-step>
                        </el-steps>
                    </div>
                    <div class="flex-1 flex flex-col overflow-hidden">
                        <div v-if="active==0" class="h-full flex flex-col overflow-hidden">
                            <div class="flex-1 overflow-auto">

                                <!-- nombre -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">
                                    <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('username')}}</span>
                                    <div class="h-8 w-full">
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="{'border border-red':error=='name', 'bg-box-darkmode': mode == 'darkmode', 'bg-box-whitemode': mode == 'whitemode'}">
                                            <form autocomplete="off" class="w-full">
                                                <input type="text" name="newusermail" :placeholder="$t('username')" class="w-full text-sm" :class="'text-dfont-'+mode+' bg-box-'+mode" v-model="form.user.name">
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- surname1 -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">
                                    <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('firstSurname')}}</span>
                                    <div class="h-8 w-full">
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                                            <form autocomplete="off" class="w-full">
                                                <input type="text" name="newusermail" :placeholder="$t('firstSurname')" class="w-full text-sm" :class="'text-dfont-'+mode+' bg-box-'+mode" v-model="form.user.surname1">
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- surname2 -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">
                                    <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('secondSurname')}}</span>
                                    <div class="h-8 w-full">
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                                            <form autocomplete="off" class="w-full">
                                                <input type="text" name="newusermail" :placeholder="$t('secondSurname')" class="w-full text-sm" :class="'text-dfont-'+mode+' bg-box-'+mode" v-model="form.user.surname2">
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- emilio -->
                                <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">
                                    <span class="text-sm mb-2" :class="'text-dfont-'+mode">Email</span>
                                    <div class="h-8 w-full">
                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2" :class="{'border border-red':error=='email', 'bg-box-darkmode': mode == 'darkmode', 'bg-box-whitemode': mode == 'whitemode'}">
                                            <form autocomplete="off" class="w-full">
                                                <input type="text" name="newusermail" placeholder="Email" class="w-full text-sm" :class="'text-dfont-'+mode+' bg-box-'+mode" v-model="form.user.email">
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="h-auto bg-red rounded-lg flex flex-row justify-center mt-3 items-center" @click="showpass = !showpass">
                                    <span v-if="!showpass" class="text-white font-semibold">{{$t('changePassword')}}</span>
                                    <span v-else class="text-white font-semibold">{{$t('cancel')}}</span>
                                </div>
                                <div class="overflow-hidden mt-3 flex flex-col" :class="{'h-0': !showpass, 'h-48 pt-2': showpass}" style="transition: all .3s">
                                    <!-- password -->
                                    <div class="h-auto px-2 flex flex-col justify-center items-start">
                                        <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('newPassword')}}<small> <i class="mdi mdi-information-outline text-blue" @click="errorpass = !errorpass"></i></small></span>
                                        <div class="h-8 w-full">
                                            <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2 relative" :class="'bg-box-'+mode">
                                                <input :type="typepassword" :placeholder="$t('password')" class="w-full text-sm" :class="'text-dfont-'+mode+' bg-box-'+mode" v-model="formpassword.user.password">
                                                <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass()">
                                                    <i v-if="typepassword == 'password'" class="mdi mdi-eye" :class="'text-dfont-'+mode"></i>
                                                    <i v-if="typepassword == 'text'" class="mdi mdi-eye-off" :class="'text-dfont-'+mode"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- password2 -->
                                    <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">
                                        <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('repeatPassword')}}</span>
                                        <div class="h-8 w-full">
                                            <div class="h-full w-full rounded-lg flex flex-row justify-between items-center px-2"  :class="'bg-box-'+mode">
                                                <input :type="typepassword2" :placeholder="$t('repeatPassword')" class="w-full text-sm" :class="'text-dfont-'+mode+' bg-box-'+mode" v-model="password2">
                                                <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass2()">
                                                    <i v-if="typepassword2 == 'password'" class="mdi mdi-eye" :class="'text-dfont-'+mode"></i>
                                                    <i v-if="typepassword2 == 'text'" class="mdi mdi-eye-off" :class="'text-dfont-'+mode"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Request
                                    model="EditUser"
                                    action="update"
                                    :form="formpassword"
                                    @success="onSuccessPass"
                                    @error="onErrorPass"
                                    v-slot="{ request, loading }" class="h-auto w-6/12">
                                        <div v-if="!loading" class="h-full w-full">
                                            <div class="h-auto mt-3 py-1 w-6/12 mx-auto rounded-lg bg-red flex flex-row justify-center items-center" @click="changePassword(request)">
                                                <span class="text-white font-semibold">{{$t('change')}}</span>
                                            </div>
                                        </div>
                                    </Request>
                                </div>
                            </div>
                            <div class="h-8 flex flex-row justify-center items-center">
                                <div class="h-full w-6/12 rounded-lg bg-red flex flex-row justify-center items-center" @click="nextstep(1)">
                                    <i class="mdi mdi-arrow-right text-white"></i>
                                </div>
                            </div>
                        </div>

                        <div v-if="active==1" class="h-full flex flex-col overflow-hidden">
                            <div class="flex-1 overflow-auto">
                                <!-- videoWall -->
                                <div class="h-8 px-2 mt-6 flex flex-row justify-between items-center">
                                    <!-- <span class="text-sm mb-2" :class="'text-dfont-'+mode">Usuario Videowall</span> -->
                                     <!-- <el-switch v-model="form.user.videoWall" :active-value="1" :inactive-value="0" active-text="Usuario Videowall" inactive-text="Usuario Aplicación"></el-switch> -->
                                     <span class="text-sm font-semibold mr-auto" :class="'text-dfont-'+mode">Usuario Videowall</span>
                                    <el-switch v-model="form.user.videoWall" :active-value="0" :inactive-value="1" active-color="#409EFF" inactive-color="#409EFF"></el-switch>
                                    <span class="text-sm font-semibold ml-auto" :class="'text-dfont-'+mode">Usuario Aplicación</span>
                                </div>
                                <!-- admin -->
                                <div class="h-8 px-2 mt-6 flex flex-row justify-between items-center">
                                    <span class="text-sm mb-2" :class="'text-dfont-'+mode">{{$t('administrator')}}</span>
                                    <el-switch v-model="form.user.admin" :active-value="1" :inactive-value="0"></el-switch>
                                </div>
                                <!-- rol -->
                                <div class="h-auto px-2 mt-6 flex flex-col justify-center items-start">
                                    <span class="text-sm mb-2" :class="'text-dfont-'+mode">Rol</span>
                                    <All model="UserType" :immediate="true" v-slot="{data:usertype, loading:loadingusertype}">
                                        <div v-if="!loadingusertype" class="h-8 w-full">
                                            <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode">
                                                <!-- <el-select v-model="form.user.IdType" filterable :placeholder="$t('selectRol')" class="w-full text-dfont">
                                                    <el-option
                                                    v-for="item in usertype"
                                                    :key="item.Id"
                                                    :label="item.Name"
                                                    :value="item.Id"
                                                    class="w-full text-dfont">
                                                    </el-option>
                                                </el-select> -->
                                                <select name="-" v-model="form.user.IdType" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                    <option v-for="(el,index) in usertype" :key="index" :value="el.Id" :class="'text-dfont-'+mode">{{el.Name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </All>
                                </div>
                                <!-- <div v-if="form.user.IdType != '8d27cef0394b11ea966b005056aeaa71'" class="h-8 flex flex-row justify-center items-center mt-6" @click="gotorelations()">
                                    <span class="text-purple font-semibold">{{$t('viewRelations')}}</span>
                                </div> -->
                            </div>
                            <div class="h-8 flex flex-row justify-center items-center">
                                <div class="h-full w-5/12 mx-auto rounded-lg bg-red flex flex-row justify-center items-center" @click="prevstep()">
                                    <i class="mdi mdi-arrow-left text-white"></i>
                                </div>
                                <div class="h-full w-5/12 mx-auto">
                                    <Request
                                    model="EditUser"
                                    action="update"
                                    :form="form"
                                    @success="onSuccess"
                                    @error="onError"
                                    v-slot="{ request, loading }" class="h-full w-full">
                                        <div v-if="!loading" class="h-auto w-auto">
                                            <div v-if="form.user.IdType != '8d27cef0394b11ea966b005056aeaa71'" class="h-full w-full bg-red rounded-lg  flex flex-row justify-center items-center"  @click="request">
                                                <span class="text-white font-semibold">{{$t('editRelations')}}</span>
                                            </div>
                                            <div v-else class="h-full w-full bg-red rounded-lg  flex flex-row justify-center items-center"  @click="request">
                                                <span class="text-white font-semibold">{{$t('edit')}}</span>
                                            </div>
                                        </div>
                                    </Request>
                                    <Request
                                    model="DeleteRelations"
                                    action="delete"
                                    :form="formRelations"
                                    @success="onSuccessRelation"
                                    @error="onError"
                                    class="h-full w-full" ref="formrelation"></Request>
                                </div>
                            </div>
                        </div>

                        <div v-if="errormodal" class="absolute h-full w-full top-0 left-0 flex col justify-center items-center" style="backdrop-filter:blur(3px)">
                            <div class="h-auto w-4/5 rounded-lg shadow-card flex flex-col overflow-hidden" :class="'bg-module-'+mode">
                                <div class="h-10 w-full bg-red flex flex-row justify-between items-center px-2">
                                    <span class="text-white font-semibold">{{$t('unsafePassword')}}</span>
                                    <div class="h-10 w-10 flex flex-col justify-center items-center" @click="errormodal = !errormodal">
                                        <i class="mdi mdi-close text-white"></i>
                                    </div>
                                </div>
                                <div class="flex-1 flex flex-col justify-center items-start p-4">
                                    <span class=" font-semibold mb-2" :class="'text-dfont-'+mode"><span class="font-bold">·</span> {{$t('passwordMustBe')}}</span>
                                    <span :class="'text-dfont-'+mode"><span class="font-bold">·</span> {{$t('betweenCharacters')}}</span>
                                    <span :class="'text-dfont-'+mode"><span class="font-bold">·</span> {{$t('leastOneDigit')}}</span>
                                    <span :class="'text-dfont-'+mode"><span class="font-bold">·</span>{{$t('leastLowerAndUpperCase')}}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="errorpass" class="absolute h-full w-full top-0 left-0 flex col justify-center items-center" style="backdrop-filter:blur(3px)">
                            <div class="h-auto w-4/5 rounded-lg shadow-card flex flex-col overflow-hidden" :class="'bg-module-'+mode">
                                <div class="h-10 w-full bg-red flex flex-row justify-between items-center px-2">
                                    <span class=" font-semibold text-white">{{$t('passwordMustBe')}}</span>
                                    <div class="h-10 w-10 flex flex-col justify-center items-center" @click="errorpass = !errorpass">
                                        <i class="mdi mdi-close text-white"></i>
                                    </div>
                                </div>
                                <div class="flex-1 flex flex-col justify-center items-start p-4">
                                    <span :class="'text-dfont-'+mode"><span class="font-bold">·</span> {{$t('betweenCharacters')}}</span>
                                    <span :class="'text-dfont-'+mode"><span class="font-bold">·</span> {{$t('leastOneDigit')}}</span>
                                    <span :class="'text-dfont-'+mode"><span class="font-bold">·</span>{{$t('leastLowerAndUpperCase')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import basiclinechart from '../../components/basiclinechart.vue';
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';

export default {
    components: {
        basiclinechart,
        All,
        Request
    },
    data(){
        return{
            rols:[
                'Propiedad',
                'Gerente',
                'Comercial',
                'Representante'
            ],
            form: {
                user: {
                    id: null,
                    name: '',
                    email: '',
                    IdType: null,
                    videoWall: false,
                    admin: 0
                }
            },
            formpassword: {
                user :{
                    id: null,
                    password: ''
                }                
            },
            datauser:null,
            active:0,
            error:false,
            showpass:false,
            password2:'',
            errorpass: false,
            typepassword: 'password',
            typepassword2: 'password',
            errormodal: false,
            mailChanged: false,
            countRol:0,
            formRelations: {
                id:null
            }
        }
    },
    methods:{
        async onSuccess(response) {
            if (this.mailChanged) { this.$refs['formrelation'].request() }
            if (response.IdType === '8d27cef0394b11ea966b005056aeaa71') { this.$router.push({name:'users'}) }
            else { this.$router.push({ name: 'relations' }) }
        },
        onSuccessRelation(response) {
        },
        onError() {
            this.$message.error(state.messageError);
        },
        gotorelations() {
            this.$router.push({ name:'relations' })
        },
        nextstep(number) {
            if (number === 1) {
                let error = false
                if (this.validarEmail(this.form.user.email)) {
                    if (this.form.user.name !== '') {
                            if (this.form.user.email !== '') { error=false }
                            else { error='email' }
                    }
                    else { error='name' }
                    if (error === false) {
                        this.active++ }
                    else { this.error = error }
                }
            }
        },
        validarEmail(valor) {
            let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
            if (emailRegex.test(valor)) { return true }
            else {
                this.$message.error('Email no válido');
                return false
            }
        },
        prevstep() {
            this.active--
        },
        onSuccessPass() {
            this.$message({
                message: 'Contraseña cambiada',
                type: 'success'
            });
            this.showpass = !this.showpass
        },
        onErrorPass() {
            this.errorpass = !this.errorpass
        },
        typepass() {
            if (this.typepassword === 'password') { this.typepassword = 'text' }
            else { this.typepassword = 'password' }
        },
        typepass2() {
            if (this.typepassword2 === 'password') { this.typepassword2 = 'text' }
            else { this.typepassword2 = 'password' }
        },
        validatePass() {
            if (this.formpassword.user.password === this.password2) {
                let passRegex = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
                if (passRegex.test(this.formpassword.user.password)) { return true }
                else{ return false }
            }
            else { return false }
        },
        changePassword(request) {
            if (this.validatePass()) { request() }
            else { this.errormodal = !this.errormodal }
        }
    },
    computed: {
        query() {
            return {
                id: state.userToEdit
            }
        },
        querycompanies() {
            return {
                period: state.period,
                Dimension: state.dimensionGroup
            }
        },
        rol() {
            return this.form.user.IdType
        },
        mode(){
            return state.mode
        }
    },
    watch: {
        datauser(n,o) {
            this.form.user.id = n.id
            this.formpassword.user.id = n.id
            this.formRelations.id = n.id
            this.form.user.name = n.name
            this.form.user.email = n.email
            this.form.user.surname1 = n.surname1
            this.form.user.surname2 = n.surname2
            this.form.user.IdType = n.IdType
            this.form.user.videoWall = n.videoWall
            this.form.user.admin = n.admin
        },
        'form.user.IdType':{
            handler: function (val, oldVal) {
                if (n.user.IdType != o.user.IdType) { this.mailChanged = true }
            },
            deep: true  
        }
    }
}
</script>